import React from "react"
import PropTypes from "prop-types"
import { Heading, Stack, Text } from "@chakra-ui/core"
import { formatPrice } from "./helpers"

export function Highlight({
  title,
  value,
  isPrice,
  isBig,
  color,
  className,
  "data-testid": testId,
}) {
  return (
    <Stack spacing={0} className={className} data-testid={testId}>
      <Text fontSize="sm" color="gray.500" m="0" data-testid="title">
        {title}
      </Text>
      <Heading fontSize={{ sm: "lg" }} color={color} m="0" data-testid="value">
        {isPrice ? formatPrice(value) : value}
      </Heading>
    </Stack>
  )
}

Highlight.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPrice: PropTypes.bool,
  isBig: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
}

Highlight.defaultProps = {
  title: "",
  value: "",
  isPrice: false,
  isBig: false,
  color: "primary",
  className: "",
}

export default Highlight
