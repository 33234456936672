import React from "react"
import PropTypes from "prop-types"
import * as styles from "./intro.module.css"

export const Intro = ({ heading, text }) => {
  if (!heading && !text) {
    return null
  }

  return (
    <div className={styles.intro}>
      {heading && <h1 className={styles.heading}>{heading}</h1>}
      {text && <p className={styles.text}>{text}</p>}
    </div>
  )
}

Intro.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

Intro.defaultProps = {
  heading: "",
  text: "",
}

export default Intro
