export function formatPrice(value, locale = "en-GB", currency = "GBP") {
  const setting_locale = locale ?? process.env.REACT_APP_LOCALE
  const setting_currency = currency ?? process.env.REACT_APP_CURRENCY

  const formatter = new Intl.NumberFormat(setting_locale, {
    style: "currency",
    currency: setting_currency,
  })

  return formatter.format(value)
}
