import React from "react"
import PropTypes from "prop-types"
import "firebase/auth"
import { Intro } from "features/Intro"
import { Layout } from "features/Layout"
import * as styles from "../account.module.css"

export const KycUI = ({ onSubmit, apiError, register, errors, apiLoading }) => {
  return (
    <Layout fullHeight={true}>
      <div className={styles.container}>
        <form className={styles.contentContainer} onSubmit={onSubmit}>
          <Intro
            heading="Account Verification"
            text="Click on the following button to start your account verification process. Keep your driving license, natioanal insurance number or passport with you."
          />

          {apiError && (
            <div className="error" data-testid="error-global" role="alert">
              {apiError}
            </div>
          )}

          <div className={styles.fields}>
            <button className="btn btn--primary" disabled={apiLoading}>
              <div className="btn__text">Verify account now</div>
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

KycUI.propTypes = {
  onSubmit: PropTypes.func,
  apiError: PropTypes.string,
  register: PropTypes.any,
  errors: PropTypes.object,
  apiLoading: PropTypes.bool,
}

KycUI.defaultProps = {
  onSubmit: () => {},
  apiError: "",
  register: null,
  errors: {},
  apiLoading: false,
}
