import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { yupResolver } from "@hookform/resolvers"
import { LoginValidationSchema } from "./validation"
import { apiRequest, redirectToSuccess } from "./api"
import { SignInUI } from "./ui"

export const LoginScreen = () => {
  const [apiError, setApiError] = useState("")
  const [apiLoading, setApiLoading] = useState(false)

  const history = useHistory()
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(LoginValidationSchema),
  })

  const onSubmit = async (data) => {
    setApiLoading(true)

    try {
      await apiRequest("login", data)
      redirectToSuccess(history)
    } catch (err) {
      if (typeof err === "string") {
        setApiError(err)
      } else {
        setApiError(err.error ? err.error : "Unexpected error occurred")
      }
    } finally {
      setApiLoading(false)
    }
  }

  return (
    <SignInUI
      apiError={apiError}
      errors={errors}
      register={register}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    />
  )
}

LoginScreen.propTypes = {}

LoginScreen.defaultProps = {}

export default LoginScreen
