import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { yupResolver } from "@hookform/resolvers"
import { SignupValidationSchema } from "./validation"
import { apiRequest, redirectToSuccess } from "./api"
import { SignupUI } from "./ui"

export const SignupScreen = () => {
  const [apiError, setApiError] = useState("")
  const history = useHistory()
  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(SignupValidationSchema),
  })

  const onSubmit = async (data) => {
    try {
      await apiRequest("signup", data)
      redirectToSuccess(history)
    } catch (err) {
      if (typeof err === "string") {
        setApiError(err)
      } else {
        setApiError(err.error ? err.error : "Unexpected error occurred")
      }
    }
  }

  return (
    <SignupUI
      apiError={apiError}
      errors={errors}
      register={register}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
    />
  )
}

SignupScreen.propTypes = {}

SignupScreen.defaultProps = {}

export default SignupScreen
