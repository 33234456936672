import { createSlice } from "@reduxjs/toolkit"
import { initialState } from "../../app/store"

export const slice = createSlice({
  name: "footer",
  initialState: initialState.footer
    ? initialState.footer
    : {
        text: "",
      },
  reducers: {
    setText: (state, { payload }) => {
      state.text = payload
    },
  },
})

export const { setText } = slice.actions

export default slice.reducer
