import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Heading, Icon, Stack } from "@chakra-ui/core"

export function ArrowLink({ title, link }) {
  const Component = () => (
    <Stack isInline justifyContent="space-between" mb={2}>
      <Heading fontSize="lg" color="primary">
        {title}
      </Heading>
      <Icon name="chevron-right" size="24px" color="gray.400" />
    </Stack>
  )

  return link ? (
    <Link to={link}>
      <Component />
    </Link>
  ) : (
    <Component />
  )
}

ArrowLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
}

ArrowLink.defaultProps = {
  title: "",
  link: "",
}

export default ArrowLink
