import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

export async function apiRequest(apiURL, data) {
  try {
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password)
  } catch (err) {
    if (err.message && typeof err.message === "string") {
      throw err.message
    }

    throw err
  }
}

export function redirect(url) {
  document.location = url
}

export function redirectToSuccess(history) {
  history.push("/")
}
