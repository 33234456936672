import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { CommonRoutes } from "./common"
import { KycScreen, KycResultsScreen } from "../screens/Account/Kyc"

export const KycRoutes = (props) => {
  return (
    <>
      <CommonRoutes {...props} />
      <Route path="/account/kyc/start">
        <KycScreen />
      </Route>
      <Route path="/account/kyc/results">
        <KycResultsScreen />
      </Route>
      <Route component={KycScreen} />
    </>
  )
}

KycRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  pages: PropTypes.array,
}

KycRoutes.defaultProps = {
  isLoggedIn: null,
  pages: [],
}

export default KycRoutes
