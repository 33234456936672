import React from "react"
import { useSelector, useStore } from "react-redux"
import { FooterTabs } from "./ui"

export default () => {
  const reducer = require("./reducer")
  const reducerAccount = require("screens/Account/reducer")

  const store = useStore()
  store.injectReducer(reducer.slice.name, reducer.default)
  store.injectReducer(reducerAccount.slice.name, reducerAccount.default)

  const text = useSelector((state) => state.footer.text)
  const isLoggedIn = useSelector((state) => state.account.isLoggedIn)

  if (!isLoggedIn) {
    return null
  }

  return <FooterTabs text={text} />
}
