import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import { useLocation, Link } from "react-router-dom"
import { KycValidationSchema } from "./validation"
import { kycRedirectUrl, redirect, kycVerify } from "./api"
import { KycUI } from "./ui"
import * as styles from "../account.module.css"
import { Layout } from "features/Layout"
import { Intro } from "features/Intro"

export const KycScreen = () => {
  const [apiError, setApiError] = useState("")
  const [apiLoading, setApiLoading] = useState(false)
  const uid = useSelector((state) => state.account?.token?.uid)

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(KycValidationSchema),
  })

  const onSubmit = async (data) => {
    setApiLoading(true)

    try {
      redirect(await kycRedirectUrl(uid))
    } catch (err) {
      setApiLoading(false)

      if (typeof err === "string") {
        setApiError(err)
      } else {
        setApiError(err.message ? err.message : "Unexpected error occurred")
      }
    }
  }

  return (
    <KycUI
      apiError={apiError}
      errors={errors}
      register={register}
      onSubmit={handleSubmit(onSubmit)}
      apiLoading={apiLoading}
    />
  )
}

export const KycResultsScreen = () => {
  const [heading, setHeading] = useState("")
  const [text, setText] = useState("")
  const [active, setActive] = useState(false)

  const { search } = useLocation()
  const querystring = new URLSearchParams(search.substr(1))
  const uid = querystring.get("uid")
  const code = querystring.get("code")

  useEffect(() => {
    async function apiRequest() {
      if (!uid) {
        setHeading("Invalid Request")
        setText("Unable to detect your User ID. Please start validation again.")
        return
      }

      if (!code) {
        setHeading("Cancelled Request")
        setText("You have cancelled your ID verification. Please start again.")
        return
      }

      setHeading("Please wait")
      setText("We are verifying your account.")

      try {
        await kycVerify(uid, code)
        setActive(true)
        setHeading("Account verified")
        setText("Your account has been activated.")
      } catch (err) {
        setHeading("An error occurred while processing your request.")
        setText(err.message ? err.message : "Unexpected error occurred")
      }
    }

    apiRequest()
  }, [code, uid])

  return (
    <Layout fullHeight={true}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <Intro heading={heading} text={text} />
          {active && (
            <Link to="/" className="btn btn--primary">
              Click here to view Dashboard
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

KycScreen.propTypes = {}

KycScreen.defaultProps = {}

export default KycScreen
