import { createSlice } from "@reduxjs/toolkit"
import { initialState } from "../../app/store"

export const slice = createSlice({
  name: "investment_dashboard",
  initialState: initialState.investment_dashboard
    ? initialState.investment_dashboard
    : {
        first_name: "",
        surname: "",
        last_login: "",
        main_account: "",
        ifisa_account: "",
        portfolio_account: "",
        cash_balance: "",
        investments: null,
        selectedTab: "",
        disableCurrentInvestments: true,
        disableRepaid: true,
        disableCancelled: true,
      },
  reducers: {
    onSelectTab: (state, { payload }) => {},

    setProfile: (state, { payload }) => {
      const {
        main_account,
        ifisa_account,
        portfolio_account,
        cash_balance,
      } = payload

      state.main_account = main_account
      state.ifisa_account = ifisa_account
      state.portfolio_account = portfolio_account
      state.cash_balance = cash_balance
    },

    setInvestments: (state, { payload }) => {
      const { investments } = payload

      state.investments =
        Array.isArray(investments) && investments.length > 0
          ? investments
          : null
    },
  },

  extraReducers: (builder) => {
    builder.addCase("reset", (state, action) => {
      state.first_name = ""
      state.surname = ""
      state.last_login = ""
      state.main_account = ""
      state.ifisa_account = ""
      state.portfolio_account = ""
      state.cash_balance = ""
      state.selectedTab = ""
      state.disableCurrentInvestments = true
      state.disableRepaid = true
      state.disableCancelled = true
      state.investments = null
    })
  },
})

export const { onSelectTab, setProfile, setInvestments } = slice.actions

export default slice.reducer
