import { useEffect } from "react"
import { useDispatch, useStore } from "react-redux"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

export default () => {
  const store = useStore()
  const dispatch = useDispatch()

  const reducerAccount = require("screens/Account/reducer")
  const reducerInvestmentDashboard = require("screens/InvestmentDashboard/reducer")
  const reducerPropertyDetail = require("screens/PropertyDetail/reducer")

  store.injectReducer(reducerAccount.slice.name, reducerAccount.default)
  store.injectReducer(
    reducerInvestmentDashboard.slice.name,
    reducerInvestmentDashboard.default
  )
  store.injectReducer(
    reducerPropertyDetail.slice.name,
    reducerPropertyDetail.default
  )

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      const db = firebase.firestore()
      const isLoggedIn = !!user

      //dispatch({type: "reset"})
      dispatch(reducerAccount.setIsLoggedIn(isLoggedIn))

      if (isLoggedIn) {
        const { uid } = firebase.auth().currentUser
        const token = await firebase.auth().currentUser.getIdTokenResult()
        if (token && token.claims) {
          dispatch(
            reducerAccount.setToken({
              ...token.claims,
              uid,
            })
          )
        }

        // Step 1 - Download Profile
        const profileRef = db.doc(`/investor_profile/${uid}`)
        profileRef.onSnapshot(function (doc) {
          if (!doc.exists) {
            return
          }

          const { first_name, surname, last_login } = doc.data()

          dispatch(
            reducerAccount.setProfile({ first_name, surname, last_login })
          )
        })

        // Step 2 - Download Private account/financial information
        const statementRef = db.doc(`/investor_profile_private/${uid}`)
        statementRef.onSnapshot(function (doc) {
          if (!doc.exists) {
            return
          }

          const {
            main_account,
            ifisa_account,
            portfolio_account,
            cash_balance,
            activated,
          } = doc.data()

          dispatch(
            reducerInvestmentDashboard.setProfile({
              main_account,
              ifisa_account,
              portfolio_account,
              cash_balance,
            })
          )

          dispatch(reducerAccount.setActivated(!!activated))
        })

        // Step 3 - Download Private account/financial information
        const investmentRef = db.collection(
          `/investor_profile/${uid}/investments`
        )
        investmentRef.onSnapshot(function (querySnapshot) {
          const investments = []

          querySnapshot.forEach(function (doc) {
            const entry = doc.data()
            investments.push({
              ...entry,
              id: doc.id,
            })
          })

          dispatch(reducerInvestmentDashboard.setInvestments({ investments }))
        })

        // Step 4 - Private Properties information
        const privatePropertiesRef = db.collection(`properties_private`)
        privatePropertiesRef.onSnapshot(function (querySnapshot) {
          const properties = {}

          querySnapshot.forEach(function (doc) {
            properties[doc.id] = {
              ...doc.data(),
              id: doc.id,
            }
          })

          dispatch(reducerPropertyDetail.setProperties({ properties }))
        })
      }
    })
  })

  return null
}
