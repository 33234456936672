import { useEffect } from "react"
import { useHistory } from "react-router"
import { apiRequest } from "./api"

export const SignOutScreen = () => {
  const history = useHistory()

  useEffect(() => {
    apiRequest().finally(() => {
      history.push("/")
    })
  }, [history])

  return null
}

SignOutScreen.propTypes = {}

SignOutScreen.defaultProps = {}

export default SignOutScreen
