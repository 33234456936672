import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

export async function apiRequest(apiURL, data) {
  try {
    const { user } = await firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password)

    await user.updateProfile({
      displayName: `${data.title} ${data.firstname} ${data.lastname}`,
    })

    const profileRef = firebase.firestore().collection("investor_profile")

    await profileRef.doc(user.uid).set({
      title: data.title,
      firstname: data.firstname,
      lastname: data.lastname,
      investor_type: data.investor_type,
      account_type: data.account_type,
      subscribe: data.subscribe,
    })
  } catch (err) {
    if (err.message && typeof err.message === "string") {
      throw err.message
    }

    throw err
  }
}

export function redirectToSuccess(history) {
  history.push("/account/kyc/start")
}
