import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import "features/basecss"
import * as styles from "./layout.module.css"
import { Box, Heading } from "@chakra-ui/core"
import { Link } from "react-router-dom"

export const LayoutHeadingLink = ({ link, children }) => {
  if (!link) {
    return children
  }

  return <Link to={link}>{children}</Link>
}

export const LayoutHeading = ({ link, heading }) => {
  if (!heading) {
    return null
  }

  return (
    <LayoutHeadingLink link={link}>
      <Box bg="primary" color="white" p={5} mb={0}>
        <Heading fontSize="xl" color="white" m={0}>
          {heading}
        </Heading>
      </Box>
    </LayoutHeadingLink>
  )
}

export const Layout = ({
  fullHeight,
  children,
  noPadding,
  className,
  heading,
  headingLink,
}) => {
  const classNames = cx({
    [styles.inner]: true,
    [className]: !!className,
    [styles["inner--full"]]: fullHeight,
    [styles["inner--no-padding"]]: noPadding,
  })

  return (
    <div className={styles.container} role="main">
      <LayoutHeading heading={heading} link={headingLink} />

      <div className={classNames}>{children}</div>
    </div>
  )
}

Layout.propTypes = {
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
}

Layout.defaultProps = {
  fullHeight: false,
  className: "",
  noPadding: false,
}

export default Layout
