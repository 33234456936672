import React, { useState } from "react"
import PropTypes from "prop-types"
import firebase from "firebase/app"
import "firebase/auth"
import cx from "classnames"
import { Intro } from "../../../features/Intro"
import { Link } from "react-router-dom"
import Separator from "../../../features/Separator"
import { Layout } from "../../../features/Layout"
import GoogleIcon from "icons/google.svg"
import * as styles from "../account.module.css"

export const GoogleSignupButton = () => {
  const [disabled, setDisabled] = useState(false)

  const classNames = cx({
    btn: true,
    "btn--secondary": true,
    "btn--fullwidth": true,
  })

  const onClick = async () => {
    setDisabled(true)

    try {
      const provider = new firebase.auth.GoogleAuthProvider()

      firebase.auth().signInWithPopup(provider)
    } catch (err) {
      console.error(err)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <button
      className={classNames}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <div className="btn__icon">
        <GoogleIcon />
      </div>
      <div className="btn__text">Login with Google</div>
    </button>
  )
}

export const SignInUI = ({ onSubmit, apiError, register, errors }) => {
  return (
    <Layout fullHeight={true}>
      <div className={styles.container}>
        <form className={styles.contentContainer} onSubmit={onSubmit}>
          <Intro heading="Login" text="" />

          {apiError && (
            <div className="error" data-testid="error-global" role="alert">
              {apiError}
            </div>
          )}

          <div className={styles.fields}>
            <div className="field-item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="input-field"
                id="email"
                name="email"
                ref={register}
                aria-invalid={!!errors.email}
              />
              {errors.email && (
                <div
                  className="field-item__error"
                  data-testid="error-email"
                  role="alert"
                >
                  {errors.email.message}
                </div>
              )}
            </div>

            <div className="field-item field-item--relative">
              <label htmlFor="password">Password</label>
              <Link to="/account/forgot" className={styles.forgotPasswordLink}>
                Forgot Password?
              </Link>
              <input
                type="password"
                className="input-field"
                id="password"
                name="password"
                ref={register}
                aria-invalid={!!errors.password}
              />
              {errors.password && (
                <div
                  className="field-item__error"
                  data-testid="error-password"
                  role="alert"
                >
                  {errors.password.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <div className={styles.submitButtons}>
                <div className={styles.btnNext}>
                  <button className="btn btn--primary" data-testid="submit">
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.otherLogin}>
            <div className="field-item">
              <Separator>
                <div>Or</div>
              </Separator>
            </div>

            <div className="field-item">
              <Link
                className="btn btn--secondary btn--fullwidth"
                to="/account/signup"
              >
                Sign up
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

SignInUI.propTypes = {
  onSubmit: PropTypes.func,
  apiError: PropTypes.string,
  register: PropTypes.any,
  errors: PropTypes.object,
}

SignInUI.defaultProps = {
  onSubmit: () => {},
  apiError: "",
  register: null,
  errors: {},
}
