import firebase from "firebase/app"
import "firebase/auth"

export async function apiRequest(apiURL, data) {
  await firebase.auth().sendPasswordResetEmail(data.email)
}

export function redirectToSuccess(history) {
  history.push("/forgot-success")
}
