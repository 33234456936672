import React from "react"
import { Box, Spinner } from "@chakra-ui/core"

export default function Loading({ message }) {
  return (
    <Box d="flex" w="100%" alignItems="center" justifyContent="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        label={message}
      />
    </Box>
  )
}
