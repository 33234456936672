import React from "react"
import PropTypes from "prop-types"
import * as styles from "../account.module.css"
import { Intro } from "../../../features/Intro"
import { Link } from "react-router-dom"
import Separator from "../../../features/Separator"
import { Layout } from "../../../features/Layout"

export const ForgotUI = ({ onSubmit, apiError, register, errors }) => {
  return (
    <Layout fullHeight={true}>
      <div className={styles.container}>
        <form className={styles.contentContainer} onSubmit={onSubmit}>
          <Intro heading="Forgot Password" text="" />

          {apiError && (
            <div className="error" data-testid="error-global" role="alert">
              {apiError}
            </div>
          )}

          <div className={styles.fields}>
            <div className="field-item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="input-field"
                id="email"
                name="email"
                ref={register}
                aria-invalid={!!errors.email}
              />
              {errors.email && (
                <div
                  className="field-item__error"
                  data-testid="error-email"
                  role="alert"
                >
                  {errors.email.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <div className={styles.submitButtons}>
                <div className={styles.btnNext}>
                  <button className="btn btn--primary" data-testid="submit">
                    Email new password
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.otherLogin}>
            <div className="field-item">
              <Separator>
                <div>Or</div>
              </Separator>
            </div>

            <div className="field-item">
              <Link
                className="btn btn--secondary btn--fullwidth"
                to="/account/login"
              >
                Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

ForgotUI.propTypes = {
  onSubmit: PropTypes.func,
  apiError: PropTypes.string,
  register: PropTypes.any,
  errors: PropTypes.object,
}

ForgotUI.defaultProps = {
  onSubmit: () => {},
  apiError: "",
  register: null,
  errors: {},
}
