import React from "react"
import { NavLink } from "react-router-dom"
import { Box, Stack, Text, Flex } from "@chakra-ui/core"
import CurrentInvestments from "icons/current_investments.svg"
import IfisaIcon from "icons/ifisa_icon.svg"
import ThirdPartyIcon from "icons/third_party_icon.svg"
import MessageIcon from "icons/message_icon.svg"
import NewsIcon from "icons/news_icon.svg"

import * as styles from "./footer.module.css"

export const FooterIcon = ({ children, label1, label2, link }) => {
  return (
    <NavLink
      to={link}
      className={styles.link}
      activeClassName={styles.linkActive}
    >
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <Flex
          width="32px"
          height="32px"
          mb={2}
          backgroundColor="primary"
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Flex>
        <Text textAlign="center" fontSize="xs" mb={0} pb={0} lineHeight={1}>
          {label1}
        </Text>
        <Text textAlign="center" fontSize="xs" mb={0} pb={0} lineHeight={1}>
          {label2}
        </Text>
      </Flex>
    </NavLink>
  )
}

export const FooterTabs = () => {
  return (
    <div className={styles.footer}>
      <Box backgroundColor="primary" color="white" padding={4}>
        <Stack
          isInline
          spacing={2}
          justifyContent="space-between"
          shouldWrapChildren={true}
        >
          <FooterIcon
            label1="Current"
            label2="Investments"
            link="/investments/current/success"
          >
            <CurrentInvestments />
          </FooterIcon>

          <FooterIcon
            label1="IFISA"
            label2="Investments"
            link="/investments/ifisa/success"
          >
            <IfisaIcon />
          </FooterIcon>

          <FooterIcon
            label1="Third Party"
            label2="Investments"
            link="/investments/thirdparty/success"
          >
            <ThirdPartyIcon />
          </FooterIcon>

          <FooterIcon label1="Messages" link="/messages">
            <MessageIcon />
          </FooterIcon>

          <FooterIcon label1="News" link="/news">
            <NewsIcon />
          </FooterIcon>
        </Stack>
      </Box>
    </div>
  )
}
