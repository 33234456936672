import React from "react"
import PropTypes from "prop-types"
import { Box, Heading, Grid, Text } from "@chakra-ui/core"
import { Layout } from "features/Layout"
import { Highlight } from "features/Highlight"
import * as styles from "./investment-dashboard.module.css"

export const InvestmentDashboardUI = ({
  first_name,
  surname,
  last_login,
  main_account,
  ifisa_account,
  portfolio_account,
  cash_balance,
}) => {
  return (
    <Layout fullHeight={true} noPadding={false} heading="Investments Portfolio">
      <div className={styles.container}>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={3}
          mb={3}
          pb={3}
          borderBottom="1px solid"
          borderBottomColor="gray.500"
        >
          <Highlight
            title="Main Account"
            value={main_account}
            data-testid="main_account"
            isPrice={true}
            isBig={true}
          />
          <Highlight
            title="IFISA Account"
            value={ifisa_account}
            data-testid="ifisa_account"
            isPrice={true}
            isBig={true}
          />
          <Highlight
            title="Portfolio Account"
            value={portfolio_account}
            data-testid="portfolio_account"
            isPrice={true}
            isBig={true}
          />
          <Highlight
            title="Cash Balance"
            value={cash_balance}
            data-testid="cash_balance"
            isPrice={true}
            isBig={true}
          />
        </Grid>

        <Box mt={6}>
          <Heading fontSize="lg" mb={8}>
            Investment Opportunites
          </Heading>
          <Text textAlign="center">
            We currently have no investment opportunities available
          </Text>
        </Box>
      </div>
    </Layout>
  )
}

InvestmentDashboardUI.propTypes = {
  first_name: PropTypes.string,
  surname: PropTypes.string,
  last_login: PropTypes.string,
  main_account: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ifisa_account: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  portfolio_account: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cash_balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

InvestmentDashboardUI.defaultProps = {
  first_name: "",
  surname: "",
  last_login: "",
  main_account: "",
  ifisa_account: "",
  portfolio_account: "",
  cash_balance: "",
}
