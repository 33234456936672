import React from "react"
import { useSelector, useStore } from "react-redux"
import { Header } from "./index"

export default () => {
  const reducer = require("./reducer")
  const reducerAccount = require("screens/Account/reducer")

  const store = useStore()
  store.injectReducer(reducer.slice.name, reducer.default)
  store.injectReducer(reducerAccount.slice.name, reducerAccount.default)

  const brandName1 = useSelector((state) => state.header.brandName1)
  const brandName2 = useSelector((state) => state.header.brandName2)
  const links = useSelector((state) => state.header.links)
  const isLoggedIn = useSelector((state) => state.account.isLoggedIn)

  return (
    <Header
      brandName1={brandName1}
      brandName2={brandName2}
      links={links}
      isLoggedIn={isLoggedIn}
    />
  )
}
