import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import {
  Checkbox,
  Stack,
  Heading,
  Flex,
  Select,
  Text,
  Radio,
  RadioGroup,
} from "@chakra-ui/core"
import { Link } from "react-router-dom"
import Separator from "features/Separator"
import { Layout } from "features/Layout"
import * as styles from "../account.module.css"

export const SignupUI = ({ onSubmit, apiError, register, errors, control }) => {
  const [investorType, setInvestorType] = useState("")
  const [investorDescription, setInvestorDescription] = useState("")

  const descriptionMap = {
    "Investment Professional":
      "An individual who is authorised to complete an application on behalf of a business whose ordinary activities involve it investing in shares or other financial instruments.",
    "High Net-Worth Body":
      "Who is authorised to complete an application on behalf of a company or partnership with net assets of at least £5 million or a trust with total assets of at least £10 million.",
    "Certified High Net Worth Investor":
      "An individual who has signed, within the period of twelve months ending with the day on which communication is made, a statement to confirm they have an annual income of not less than £100,000, or net assets, excluding their primary residence, of not less than £250,000.",
    "Self-Certified Sophisticated Investor":
      "An individual who has signed, within the period of 12 months ending with the day on which communication is made, a statement to confirm they have sufficient knowledge to understand the risks associated with engaging in investment activity in non-mainstream pooled investments.",
  }

  const onChangeInvestorType = (evt) => {
    setInvestorType(evt.target.value)
  }

  useEffect(() => {
    setInvestorDescription(descriptionMap[investorType])
  }, [investorType, descriptionMap])

  return (
    <Layout fullHeight={true}>
      <div className={styles.container}>
        <form className={styles.contentContainer} onSubmit={onSubmit}>
          <Stack mb={2}>
            <Heading fontSize="lg" mb={2}>
              Sign up
            </Heading>
            <p>
              To get access to full details on our investment opportunities you
              need to be registered on our platform and accredited as being
              either a High Net Worth or Sophisticated investor.
            </p>
            <p>
              Please use this form to provide the initial information and to
              select your investor classification, you can then complete the
              registration process at a time that suits you.
            </p>
          </Stack>

          {apiError && (
            <div className="error" data-testid="error-global" role="alert">
              {apiError}
            </div>
          )}

          <Stack isInline={true}>
            <Flex flexDirection="column" flex="1">
              <div className="field-item">
                <label htmlFor="title">Title</label>

                <Select
                  placeholder="Select title"
                  size="lg"
                  id="title"
                  name="title"
                  ref={register}
                  isInvalid={!!errors.title}
                  borderColor="var(--borderColor)"
                >
                  <option value="Mrs" data-testid="mrs">
                    Mrs
                  </option>
                  <option value="Miss" data-testid="miss">
                    Miss
                  </option>
                  <option value="Ms" data-testid="ms">
                    Ms
                  </option>
                  <option value="Mr" data-testid="mr">
                    Mr
                  </option>
                  <option value="Dr" data-testid="dr">
                    Dr
                  </option>
                </Select>

                {errors.title && (
                  <div
                    className="field-item__error"
                    data-testid="error-title"
                    role="alert"
                  >
                    {errors.title.message}
                  </div>
                )}
              </div>
            </Flex>

            <Flex flexDirection="column" flex="2">
              <div className="field-item">
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  className="input-field"
                  id="firstname"
                  name="firstname"
                  ref={register}
                  placeholder=""
                  aria-invalid={!!errors.firstname}
                />
                {errors.firstname && (
                  <div
                    className="field-item__error"
                    data-testid="error-firstname"
                    role="alert"
                  >
                    {errors.firstname.message}
                  </div>
                )}
              </div>
            </Flex>

            <Flex flexDirection="column" flex="2">
              <div className="field-item">
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  className="input-field"
                  id="lastname"
                  name="lastname"
                  ref={register}
                  placeholder=""
                  aria-invalid={!!errors.lastname}
                />
                {errors.lastname && (
                  <div
                    className="field-item__error"
                    data-testid="error-lastname"
                    role="alert"
                  >
                    {errors.lastname.message}
                  </div>
                )}
              </div>
            </Flex>
          </Stack>

          <div className={styles.fields}>
            <div className="field-item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="input-field"
                id="email"
                name="email"
                ref={register}
                aria-invalid={!!errors.email}
              />
              {errors.email && (
                <div
                  className="field-item__error"
                  data-testid="error-email"
                  role="alert"
                >
                  {errors.email.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="input-field"
                id="password"
                name="password"
                ref={register}
                aria-invalid={!!errors.password}
              />
              {errors.password && (
                <div
                  className="field-item__error"
                  data-testid="error-password"
                  role="alert"
                >
                  {errors.password.message}
                </div>
              )}
            </div>

            <div className="field-item field-item--relative">
              <label htmlFor="passwordConfirmation">Confirm Password</label>
              <input
                type="password"
                className="input-field"
                id="passwordConfirmation"
                name="passwordConfirmation"
                ref={register}
                aria-invalid={!!errors.passwordConfirmation}
              />
              {errors.passwordConfirmation && (
                <div
                  className="field-item__error"
                  data-testid="error-password-confirm"
                  role="alert"
                >
                  {errors.passwordConfirmation.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <label htmlFor="investor_type">
                Please choose the Investor Type that applies to you as below,
                when completing your registration.
              </label>

              <Select
                placeholder="Select Investor Type"
                size="lg"
                id="investor_type"
                name="investor_type"
                ref={register}
                isInvalid={!!errors.investor_type}
                borderColor="var(--borderColor)"
                onChange={onChangeInvestorType}
                data-testid="investor_type"
              >
                <option value="Investment Professional">
                  Investment Professional
                </option>
                <option value="High Net-Worth Body">High Net-Worth Body</option>
                <option value="Certified High Net Worth Investor">
                  Certified High Net Worth Investor
                </option>
                <option value="Self-Certified Sophisticated Investor">
                  Self-Certified Sophisticated Investor
                </option>
              </Select>

              {investorType && (
                <Text fontSize="sm" mt={2}>
                  {investorDescription}
                </Text>
              )}

              {errors.investor_type && (
                <div
                  className="field-item__error"
                  data-testid="error-investor_type"
                  role="alert"
                >
                  {errors.investor_type.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <label htmlFor="account_type">
                Is this account for you or your company?
              </label>

              <Controller
                as={
                  <RadioGroup ref={register} name="account_type">
                    <Radio
                      value="myself"
                      alignItems="flex-start"
                      data-testid="account_type_myself"
                    >
                      <Text
                        fontWeight="normal"
                        fontSize="sm"
                        lineHeight={1.4}
                        mb={0}
                        pb={0}
                      >
                        Myself
                      </Text>
                    </Radio>
                    <Radio
                      value="company"
                      alignItems="flex-start"
                      data-testid="account_type_company"
                    >
                      <Text
                        fontWeight="normal"
                        fontSize="sm"
                        lineHeight={1.4}
                        mb={0}
                        pb={0}
                      >
                        Company
                      </Text>
                    </Radio>
                  </RadioGroup>
                }
                name="account_type"
                data-testid="account_type"
                control={control}
                defaultValue=""
              />

              {errors.account_type && (
                <div
                  className="field-item__error"
                  data-testid="error-account_type"
                  role="alert"
                >
                  {errors.account_type.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <Checkbox
                ref={register}
                name="subscribe"
                data-testid="subscribe"
                alignItems="flex-start"
              >
                <Text
                  fontWeight="normal"
                  fontSize="sm"
                  lineHeight={1.4}
                  mb={0}
                  pb={0}
                >
                  Please send me priority alerts to update me about investment
                  opportunities and other relevant news.
                </Text>
              </Checkbox>

              {errors.subscribe && (
                <div
                  className="field-item__error"
                  data-testid="error-subscribe"
                  role="alert"
                >
                  {errors.subscribe.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <Checkbox
                ref={register}
                name="terms"
                data-testid="terms"
                alignItems="flex-start"
              >
                <Text
                  fontWeight="normal"
                  fontSize="sm"
                  lineHeight={1.4}
                  mb={0}
                  pb={0}
                >
                  By clicking ‘Register’ you are confirming your acceptance to
                  the Investor Terms and Conditions & Privacy Policy.
                </Text>
              </Checkbox>

              {errors.terms && (
                <div
                  className="field-item__error"
                  data-testid="error-terms"
                  role="alert"
                >
                  {errors.terms.message}
                </div>
              )}
            </div>

            <div className="field-item">
              <div className={styles.submitButtons}>
                <div className={styles.btnNext}>
                  <button className="btn btn--primary" data-testid="submit">
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.otherLogin}>
            <div className="field-item">
              <Separator>
                <div>Or</div>
              </Separator>
            </div>
            <div className="field-item">
              <Link
                className="btn btn--secondary btn--fullwidth"
                to="/account/login"
              >
                Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

SignupUI.propTypes = {
  onSubmit: PropTypes.func,
  apiError: PropTypes.string,
  register: PropTypes.any,
  errors: PropTypes.object,
}

SignupUI.defaultProps = {
  onSubmit: () => {},
  apiError: "",
  register: null,
  errors: {},
}
