import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

import { isLocalhost } from "./helpers"

export function init() {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  })

  if (isLocalhost()) {
    firebase.firestore().useEmulator("localhost", "8080")
    firebase.auth().useEmulator("http://localhost:9099/")
    firebase.functions().useEmulator("localhost", 5001)
    firebase.app().functions("europe-west2").useEmulator("localhost", 5001)

    window.firebase = firebase
  }
}
