import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import InvestmentDashboard from "../screens/InvestmentDashboard"
import Investment from "../screens/Investment"
import { CommonRoutes } from "./common"
import SignOutScreen from "../screens/Account/Signout"
import { KycScreen, KycResultsScreen } from "../screens/Account/Kyc"

export const PrivateRoutes = (props) => {
  return (
    <>
      <CommonRoutes {...props} />
      <Route path="/investments/:investmentType/:investmentStatus/:investmentId?">
        <Investment />
      </Route>
      <Route path="/account/signout">
        <SignOutScreen />
      </Route>
      <Route path="/account/kyc/start">
        <KycScreen />
      </Route>
      <Route path="/account/kyc/results">
        <KycResultsScreen />
      </Route>
      <Route path="/" exact={true}>
        <InvestmentDashboard />
      </Route>
    </>
  )
}

PrivateRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  pages: PropTypes.array,
}

PrivateRoutes.defaultProps = {
  isLoggedIn: null,
  pages: [],
}

export default PrivateRoutes
