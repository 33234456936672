import React from "react"
import { useSelector, useStore } from "react-redux"
import { Routes } from "./index"

export default () => {
  const reducerAccount = require("../screens/Account/reducer")
  const reducerPages = require("../screens/Page/reducer")

  const store = useStore()
  store.injectReducer(reducerAccount.slice.name, reducerAccount.default)
  store.injectReducer(reducerPages.slice.name, reducerPages.default)

  const isLoggedIn = useSelector((state) => state.account.isLoggedIn)
  const activated = useSelector((state) => state.account.activated)
  const pages = useSelector((state) => state.pages.pages)

  return <Routes isLoggedIn={isLoggedIn} pages={pages} activated={activated} />
}
