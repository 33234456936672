import { createSlice } from "@reduxjs/toolkit"
import { initialState } from "../../app/store"

export const slice = createSlice({
  name: "account",
  initialState: initialState.account
    ? initialState.account
    : {
        isLoggedIn: null,
        token: null,
        first_name: "",
        surname: "",
        last_login: "",
        activated: false,
      },
  reducers: {
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload
    },

    setToken: (state, { payload }) => {
      state.token = payload
    },

    setProfile: (state, { payload }) => {
      const { first_name, surname, last_login } = payload

      state.first_name = first_name
      state.surname = surname
      state.last_login = last_login
    },

    setActivated: (state, { payload }) => {
      state.activated = payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase("reset", (state, action) => {
      state.isLoggedIn = null
      state.token = null
      state.first_name = ""
      state.surname = ""
      state.last_login = ""
      state.activated = false
    })
  },
})

export const {
  setIsLoggedIn,
  setToken,
  setProfile,
  setActivated,
} = slice.actions

export default slice.reducer
