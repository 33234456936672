import { createSlice } from "@reduxjs/toolkit"
import { initialState } from "../../app/store"

export const slice = createSlice({
  name: "header",
  initialState: initialState.header
    ? initialState.header
    : {
        brandName1: "",
        brandName2: "",
        links: [],
      },
  reducers: {
    setBrandName1: (state, { payload }) => {
      state.brandName1 = payload
    },
    setBrandName2: (state, { payload }) => {
      state.brandName2 = payload
    },
    setLinks: (state, { payload }) => {
      state.links = payload
    },
  },
})

export const { setBrandName1, setBrandName2, setLinks } = slice.actions

export default slice.reducer
