import React from "react"
import { useSelector, useStore } from "react-redux"
import { InvestmentDashboardUI } from "./ui"

export default () => {
  const reducer = require("./reducer")
  const reducerAccount = require("screens/Account/reducer")

  const store = useStore()
  store.injectReducer(reducer.slice.name, reducer.default)
  store.injectReducer(reducerAccount.slice.name, reducerAccount.default)

  const first_name = useSelector((state) => state.account.first_name)
  const surname = useSelector((state) => state.account.surname)
  const last_login = useSelector((state) => state.account.last_login)
  const main_account = useSelector(
    (state) => state.investment_dashboard.main_account
  )
  const ifisa_account = useSelector(
    (state) => state.investment_dashboard.ifisa_account
  )
  const portfolio_account = useSelector(
    (state) => state.investment_dashboard.portfolio_account
  )
  const cash_balance = useSelector(
    (state) => state.investment_dashboard.cash_balance
  )

  return (
    <InvestmentDashboardUI
      first_name={first_name}
      surname={surname}
      last_login={last_login}
      main_account={main_account}
      ifisa_account={ifisa_account}
      portfolio_account={portfolio_account}
      cash_balance={cash_balance}
    />
  )
}
