import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { yupResolver } from "@hookform/resolvers"
import { ForgotValidationSchema } from "./validation"
import { apiRequest, redirectToSuccess } from "./api"
import { ForgotUI } from "./ui"

export const ForgotScreen = () => {
  const [apiError, setApiError] = useState("")
  const history = useHistory()
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(ForgotValidationSchema),
  })

  const onSubmit = async (data) => {
    try {
      await apiRequest("forgot", data)
      redirectToSuccess(history)
    } catch (err) {
      if (typeof err === "string") {
        setApiError(err)
      } else {
        setApiError(err.error ? err.error : "Unexpected error occurred")
      }
    }
  }

  return (
    <ForgotUI
      apiError={apiError}
      errors={errors}
      register={register}
      onSubmit={handleSubmit(onSubmit)}
    />
  )
}

ForgotScreen.propTypes = {}

ForgotScreen.defaultProps = {}

export default ForgotScreen
