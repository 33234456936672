import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Box, Heading, Image, Grid } from "@chakra-ui/core"
import { Layout } from "features/Layout"
import { Highlight } from "features/Highlight"
import { Intro } from "features/Intro"
import { ArrowLink } from "features/ArrowLink"
import * as styles from "./property-detail.module.css"

export const PropertyDetailUI = ({
  image,
  amount,
  start_date,
  terms,
  percentage_completed,
  status,
  exists,
}) => {
  if (!exists) {
    return (
      <Layout fullHeight={true} noPadding={false}>
        <div className={styles.container} data-testid="error">
          <Intro
            heading="Property not found"
            text="Either you do not have access to view this property or it does not exist."
          />
        </div>
      </Layout>
    )
  }

  return (
    <Layout fullHeight={true} noPadding={false}>
      <div className={styles.container}>
        <Box bg="primary" color="white" p={5} mb={5}>
          <Link to="/">
            <Heading fontSize="xl" color="white" m={0}>
              Back to Investments Portfolio
            </Heading>
          </Link>
        </Box>

        <Box mb={6}>
          <Image
            src={image}
            data-src={image}
            alt="property"
            objectFit="cover"
            w="100%"
            data-testid="image"
          />
        </Box>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={3}
          mb={3}
          pb={3}
          borderBottom="1px solid"
          borderBottomColor="gray.500"
        >
          <Highlight
            title="Amount"
            value={amount}
            isPrice={true}
            data-testid="amount"
          />
          <Highlight
            title="Investment Date"
            value={start_date}
            data-testid="start_date"
          />
          <Highlight title="Term" value={terms} data-testid="terms" />
          <Highlight
            title="PR"
            value={percentage_completed}
            data-testid="percentage_completed"
          />

          <Highlight
            title="Order Status"
            value={status}
            color={
              status && status.toLowerCase() === "success"
                ? "green.600"
                : "red.600"
            }
            className={
              styles[
                status ? "status-" + status.toLowerCase() : "status-unknown"
              ]
            }
            data-testid="status"
          />
        </Grid>

        <Box>
          <ArrowLink title="Status" />
          <ArrowLink title="Key Documents" />
          <ArrowLink title="Updates" />
          <ArrowLink title="Payouts" />
          <ArrowLink title="Financials" />
        </Box>
      </div>
    </Layout>
  )
}

PropertyDetailUI.propTypes = {
  image: PropTypes.string,
  amount: PropTypes.string,
  start_date: PropTypes.string,
  terms: PropTypes.string,
  percentage_completed: PropTypes.string,
  status: PropTypes.string,
  exists: PropTypes.bool,
}

PropertyDetailUI.defaultProps = {
  image: "",
  amount: "",
  start_date: "",
  terms: "",
  percentage_completed: "",
  status: "",
  exists: false,
}
