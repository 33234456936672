import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Heading,
  Grid,
  Stack,
  Text,
  Flex,
  Collapse,
  IconButton,
  Image,
} from "@chakra-ui/core"
import { Link } from "react-router-dom"
import { Layout } from "features/Layout"
import { Highlight } from "features/Highlight"
import { formatPrice } from "features/Highlight/helpers"
import * as styles from "./Investment.module.css"
import { ArrowLink } from "../../features/ArrowLink"

export const DetailViewUI = ({ heading, headingLink, entry }) => {
  if (!entry) {
    return null
  }

  return (
    <Layout
      fullHeight={true}
      noPadding={false}
      heading={`Back to ${heading}`}
      headingLink={headingLink}
    >
      <div className={styles.container}>
        <Box mb={6}>
          <Image
            src={entry.image}
            data-src={entry.image}
            alt="property"
            objectFit="cover"
            w="100%"
            data-testid="image"
          />
        </Box>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={3}
          mb={3}
          pb={3}
          borderBottom="1px solid"
          borderBottomColor="gray.500"
        >
          <Highlight
            title="Amount"
            value={entry.amount}
            isPrice={true}
            data-testid="amount"
          />
          <Highlight
            title="Investment Date"
            value={entry.start_date}
            data-testid="start_date"
          />
          <Highlight title="Term" value={entry.term} data-testid="terms" />
          <Highlight
            title="PR"
            value={entry.pr}
            data-testid="percentage_completed"
          />
        </Grid>

        <Box>
          <ArrowLink title="Status" />
          <ArrowLink title="Key Documents" />
          <ArrowLink title="Updates" />
          <ArrowLink title="Payouts" />
        </Box>
      </div>
    </Layout>
  )
}

export const InvestmentDashboardHeader = ({
  first_name,
  surname,
  last_login,
}) => {
  return (
    <Stack
      isInline
      align="flex-end"
      mb={3}
      pb={3}
      borderBottom="1px solid"
      borderBottomColor="gray.500"
    >
      <Heading fontSize="lg" color="primary" marginBottom={0}>
        {first_name} {surname}
      </Heading>
      <Text fontSize="sm" color="gray.500" marginBottom={0}>
        Last login {last_login}
      </Text>
    </Stack>
  )
}

export const InvestmentLineItemTitle = ({ link, title, hasGroup, onClick }) => {
  if (hasGroup) {
    return (
      <>
        <IconButton
          onClick={onClick}
          icon="arrow-down"
          size="xs"
          bg="primary"
          position="absolute"
          left={2}
        />
        <Heading onClick={onClick} fontSize="sm" marginBottom="6px">
          {title}
        </Heading>
      </>
    )
  }

  return (
    <Link to={link}>
      <Heading fontSize="sm" marginBottom="6px">
        {title}
      </Heading>
    </Link>
  )
}

export const InvestmentLineItemUI = ({
  link,
  title,
  amount,
  start_date,
  term,
  pr,
  hasGroup,
  onClick,
}) => {
  return (
    <Box borderBottom="1px solid" borderColor="gray.300" mb={4} pb={4} pl={8}>
      <InvestmentLineItemTitle
        link={link}
        title={title}
        hasGroup={hasGroup}
        onClick={onClick}
      />

      <Stack isInline={true} justifyContent="space-between">
        <Flex flexDirection="column" flex="1">
          <Text fontSize="xs" mb={0} lineHeight={0.9}>
            Amount
          </Text>
          <Text fontSize="sm" mb={0} fontWeight="bold" color="primary">
            {formatPrice(amount)}
          </Text>
        </Flex>
        <Flex flexDirection="column" flex="1">
          <Text fontSize="xs" mb={0} lineHeight={0.9}>
            Start Date
          </Text>
          <Text fontSize="sm" mb={0} fontWeight="bold" color="primary">
            {start_date}
          </Text>
        </Flex>
        <Flex flexDirection="column" flex="1">
          <Text fontSize="xs" mb={0} lineHeight={0.9}>
            Terms
          </Text>
          <Text fontSize="sm" mb={0} fontWeight="bold" color="primary">
            {term}
          </Text>
        </Flex>
        <Flex flexDirection="column" flex="1">
          <Text fontSize="xs" mb={0} lineHeight={0.9}>
            PR
          </Text>
          <Text fontSize="sm" mb={0} fontWeight="bold" color="primary">
            {pr}
          </Text>
        </Flex>
      </Stack>
    </Box>
  )
}

export const InvestmentGroupItemUI = ({ items }) => {
  const [show, setShow] = useState(false)

  if (items.length === 1) {
    return <InvestmentLineItemUI {...items[0]} hasGroup={false} />
  }

  const handleToggle = () => setShow(!show)

  return (
    <>
      <InvestmentLineItemUI
        {...items[0]}
        hasGroup={true}
        onClick={handleToggle}
      />

      <Collapse isOpen={show}>
        {items.map((entry, index) => (
          <InvestmentLineItemUI key={index} {...entry} hasGroup={false} />
        ))}
      </Collapse>
    </>
  )
}

export const InvestmentUI = ({
  investmentType,
  heading,
  total,
  invested,
  cash_balance,
  items,
}) => {
  return (
    <Layout fullHeight={true} noPadding={false} heading={heading}>
      <div className={styles.container}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={3}
          mb={3}
          pb={3}
          borderBottom="2px solid"
          borderBottomColor="primary"
        >
          <Highlight
            title="Total"
            value={total}
            data-testid="total"
            isPrice={true}
            isBig={true}
          />
          <Highlight
            title="Invested"
            value={invested}
            data-testid="invested"
            isPrice={true}
            isBig={true}
          />
          <Highlight
            title="Cash Balance"
            value={cash_balance}
            data-testid="cash_balance"
            isPrice={true}
            isBig={true}
          />
        </Grid>

        {items.map((items, index) => (
          <InvestmentGroupItemUI key={index} items={items} />
        ))}
      </div>
    </Layout>
  )
}

InvestmentUI.propTypes = {
  heading: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cash_balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.array,
}

InvestmentUI.defaultProps = {
  heading: "",
  total: "",
  invested: "",
  cash_balance: "",
  items: [],
}
