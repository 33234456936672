import React, { useState, useEffect } from "react"
import { useSelector, useStore } from "react-redux"
import { InvestmentUI, DetailViewUI } from "./ui"
import { useParams } from "react-router-dom"
import groupBy from "lodash/groupBy"
import sum from "lodash/sum"
import flatten from "lodash/flatten"

export default () => {
  const [heading, setHeading] = useState("")
  const [items, setItems] = useState([])
  const [ifisa, setIfisa] = useState(false)

  const [singleEntry, setSingleEntry] = useState(null)

  const [totalAmount, setTotalAmount] = useState(0)
  const [investedAmount, setInvestedAmount] = useState(0)
  const [cashBalance, setCashBalance] = useState(0)

  const reducer = require("./reducer")
  const reducerInvestmentDashboard = require("screens/InvestmentDashboard/reducer")
  const reducerPropertyDetail = require("screens/PropertyDetail/reducer")
  const { investmentType, investmentStatus, investmentId } = useParams()

  const store = useStore()
  store.injectReducer(reducer.slice.name, reducer.default)
  store.injectReducer(
    reducerInvestmentDashboard.slice.name,
    reducerInvestmentDashboard.default
  )
  store.injectReducer(
    reducerPropertyDetail.slice.name,
    reducerPropertyDetail.default
  )

  const invested = useSelector(
    (state) => state.investment_dashboard.main_account
  )
  const ifisa_account = useSelector(
    (state) => state.investment_dashboard.ifisa_account
  )
  const cash_balance = useSelector(
    (state) => state.investment_dashboard.cash_balance
  )

  const investments = useSelector((state) => {
    const items = state.investment_dashboard.investments

    if (Array.isArray(items) && items.length > 0) {
      return items
    }

    return null
  })
  const properties = useSelector((state) => state.property_detail.properties)

  useEffect(() => {
    if (investmentType === "current") {
      setTotalAmount(invested)
      setInvestedAmount(invested)
      setCashBalance(0)
    }

    if (investmentType === "ifisa") {
      setTotalAmount(
        (ifisa_account ? ifisa_account : 0) + (cash_balance ? cash_balance : 0)
      )
      setInvestedAmount(ifisa_account)
      setCashBalance(cash_balance)
    }

    if (investmentType === "thirdparty") {
      setTotalAmount(0)
      setInvestedAmount(0)
      setCashBalance(0)
    }
  }, [investmentType, invested, ifisa, cash_balance, ifisa_account])

  useEffect(() => {
    const normalise = (investment) => {
      const entry = {
        id: investment.investment_id,
        propertyId: investment.propertyId,
        title: "Property Not found",
        amount: investment.original_amount,
        start_date: investment.investment_created,
        terms: "",
        pr: "",
        image: "",
        isRepaid: investment.isRepaid,
        ifisa: investment.ifisa,
        status: investment.status,
        isGroup: false,
      }

      if (investment.investment_created) {
        entry.start_date = investment.investment_created.split(" ")[0]
      }

      if (properties[investment.propertyId]) {
        const property = properties[investment.propertyId]
        entry.title = property.title
        entry.term = property.term
        entry.pr = property.pr
        entry.image = property.image
      }

      return entry
    }

    let entries = Array.isArray(investments) ? investments.map(normalise) : []

    if (investmentType === "current") {
      entries = entries.filter((inv) => !inv.ifisa)
      setHeading("CURRENT INVESTMENTS")
    }

    if (investmentType === "ifisa") {
      entries = entries.filter((inv) => inv.ifisa)
      setHeading("IFISA INVESTMENTS")
    }

    if (investmentType === "thirdparty") {
      entries = entries.filter((inv) => false) // TODO needs to be wired.
      setHeading("THIRD PARTY INVESTMENTS")
    }

    if (investmentStatus === "success") {
      entries = entries.filter(
        (inv) => inv.status === "success" && !inv.isRepaid
      )
    }

    if (investmentStatus === "repaid") {
      entries = entries.filter(
        (inv) => inv.status === "success" && inv.isRepaid
      )
    }

    if (investmentStatus === "refund") {
      entries = entries.filter((inv) => inv.status !== "success")
    }

    entries = entries.map((entry) => ({
      ...entry,
      link: `/investments/${investmentType}/${investmentStatus}/${entry.id}`,
    }))

    entries = Object.values(groupBy(entries, "propertyId")).map(
      (groupedItems) => {
        if (groupedItems.length === 1) {
          return groupedItems
        }

        const newItem = { ...groupedItems[0] }
        newItem.amount = sum(groupedItems.map((i) => i.amount))
        newItem.isGroup = true

        groupedItems.unshift(newItem)

        return groupedItems
      }
    )

    setItems(entries)
    setIfisa(investmentType === "ifisa")
  }, [investmentType, investmentStatus, investments, properties])

  useEffect(() => {
    const entry = flatten(items).find(
      (entry) => entry.isGroup === false && entry.id === investmentId
    )
    setSingleEntry(entry)
  }, [investmentId, items])

  if (investmentId) {
    const headingLink = `/investments/${investmentType}/${investmentStatus}/`

    return (
      <DetailViewUI
        heading={heading}
        entry={singleEntry}
        headingLink={headingLink}
      />
    )
  }

  return (
    <InvestmentUI
      investmentType={investmentType}
      invested={investedAmount}
      total={totalAmount}
      cash_balance={cashBalance}
      items={items}
      ifisa={ifisa}
      heading={heading}
    />
  )
}
