import React from "react"
import cx from "classnames"
import * as styles from "./separator.module.css"

export function VerticalSeparator({ className }) {
  return <div className={cx([styles.vertical, className])} />
}

export default function Separator({ children }) {
  return (
    <div className={styles.sep}>
      <div className={styles.sepText}>{children}</div>
    </div>
  )
}
