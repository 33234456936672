import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import SignupScreen from "../screens/Account/Signup"
import LoginScreen from "../screens/Account/Login"
import ForgotScreen from "../screens/Account/Forgot"
import { CommonRoutes } from "./common"

export const PublicRoutes = (props) => {
  return (
    <>
      <CommonRoutes {...props} />
      <Route path="/account/signup">
        <SignupScreen />
      </Route>
      <Route path="/account/login">
        <LoginScreen />
      </Route>
      <Route path="/account/forgot">
        <ForgotScreen />
      </Route>
      <Route path="/" exact={true}>
        <LoginScreen />
      </Route>
    </>
  )
}

PublicRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  pages: PropTypes.array,
}

PublicRoutes.defaultProps = {
  isLoggedIn: null,
  pages: [],
}

export default PublicRoutes
