import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import PageScreen from "screens/Page"
import { InvestmentUI } from "screens/Investment/ui"
import { PropertyDetailUI } from "screens/PropertyDetail/ui"

export const CommonRoutes = ({ pages }) => {
  return (
    <>
      {pages &&
        Array.isArray(pages) &&
        pages.map((page) => (
          <Route key={page.url} path={page.url}>
            <PageScreen {...page} />
          </Route>
        ))}

      <Route path="/test">
        <InvestmentUI
          heading="CURRENT INVESTMENTS"
          total="0"
          invested="0"
          cash_balance="0"
        />
      </Route>

      <Route path="/test2">
        <PropertyDetailUI
          image="/images/property.jpg"
          amount="40000"
          start_date="12 May 2020"
          terms="18Months"
          percentage_completed="27.2%"
          status="success"
          exists={true}
        />
      </Route>
    </>
  )
}

CommonRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  pages: PropTypes.array,
}

CommonRoutes.defaultProps = {
  isLoggedIn: null,
  pages: [],
}

export default CommonRoutes
