import React from "react"
import PropTypes from "prop-types"
import { Switch } from "react-router-dom"
import { PrivateRoutes } from "./private"
import { PublicRoutes } from "./public"
import { KycRoutes } from "./kyc"
import Loading from "features/Loading"

export const Routes = (props) => {
  const { isLoggedIn, activated } = props

  if (isLoggedIn === null) {
    return <Loading message="Loading SDK" />
  }

  if (isLoggedIn && activated === false) {
    return (
      <Switch>
        <KycRoutes {...props} />
      </Switch>
    )
  }

  return (
    <Switch>
      {isLoggedIn ? <PrivateRoutes {...props} /> : <PublicRoutes {...props} />}
    </Switch>
  )
}

Routes.propTypes = {
  isLoggedIn: PropTypes.bool,
  pages: PropTypes.array,
}

Routes.defaultProps = {
  isLoggedIn: null,
  pages: [],
}

export default Routes
