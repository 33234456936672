import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link, NavLink } from "react-router-dom"
import Logo from "icons/logo.svg"
import * as styles from "./header.module.css"

const NavigationItem = ({ link, text, isLast }) => (
  <>
    <li className="list-pipe__item">
      <NavLink
        to={link}
        className={styles.link}
        activeClassName={styles.activeLink}
        exact={link === "/"}
      >
        {text}
      </NavLink>
    </li>
    {!isLast && <li className="list-pipe__item list-pipe__item--sep">|</li>}
  </>
)

export const Header = ({
  brandName1,
  brandName2,
  linkAriaLabel,
  links,
  isLoggedIn,
}) => {
  const [lastIndex, setLastIndex] = useState(links.length - 1)
  const [items, setItems] = useState(links)

  useEffect(() => {
    const newItems = [...links]
    if (isLoggedIn) {
      newItems.push({
        link: "/account/signout",
        text: "Signout",
      })
    }

    setItems(newItems)
    setLastIndex(newItems.length - 1)
  }, [isLoggedIn, links])

  return (
    <div className={styles.header} role="banner">
      <div className={cx([styles.logo, "link-inherit"])}>
        <Link
          to="/"
          data-testid="header-logo"
          title={brandName1}
          className={styles.logoLink}
        >
          <Logo />
        </Link>
      </div>
      <nav className={styles.navigation} aria-label={linkAriaLabel}>
        <ul className="list-pipe link-inherit" data-testid="header-links">
          {items.map((link, index) => (
            <NavigationItem
              key={index}
              {...link}
              isLast={index === lastIndex}
            />
          ))}
        </ul>
      </nav>
    </div>
  )
}

Header.propTypes = {
  brandName1: PropTypes.string,
  brandName2: PropTypes.string,
  linkAriaLabel: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  isLoggedIn: PropTypes.bool,
}

Header.defaultProps = {
  brandName1: "Tuesday",
  brandName2: "At four",
  linkAriaLabel: "Primary Navigation",
  links: [],
  isLoggedIn: false,
}

export default Header
