import { theme as defaultTheme } from "@chakra-ui/core"

const breakpoints = ["320px", "768px", "1024px", "1440px"]
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    gray: {
      ...defaultTheme.colors.gray,
      500: "#575756",
    },
    green: {
      ...defaultTheme.colors.green,
      500: "#81dc58",
    },
    blue: {
      ...defaultTheme.colors.blue,
      100: "#cdf4fa",
      500: "#1f284d",
    },
    primary: "#1f284d",
    secondary: "#646f8f",
  },
  fonts: {
    ...defaultTheme.fonts,
    heading: `"Museo Sans Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    body: `"Museo Sans Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  },
  breakpoints,
}

export default theme
