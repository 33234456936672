import { createSlice } from "@reduxjs/toolkit"
import { initialState } from "../../app/store"

export const slice = createSlice({
  name: "pages",
  initialState: initialState.pages
    ? initialState.pages
    : {
        pages: [],
      },
  reducers: {
    setPages: (state, { payload }) => {
      state.pages = payload
    },
  },
})

export const { setPages } = slice.actions

export default slice.reducer
