import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/functions"

export function redirectUri(uid) {
  return `${window.location.protocol}//${window.location.host}/account/kyc/results?uid=${uid}`
}

export async function kycRedirectUrl(uid) {
  const kycRedirect = firebase
    .app()
    .functions("europe-west2")
    .httpsCallable("kyc-redirect")
  const url = redirectUri(uid)
  const {
    data: { verificationUrl },
  } = await kycRedirect({ url })

  return verificationUrl
}

export async function kycVerify(uid, code) {
  const kycResults = firebase
    .app()
    .functions("europe-west2")
    .httpsCallable("kyc-results")

  const url = redirectUri(uid)

  const {
    data: { success },
  } = await kycResults({ url, code })

  return success
}

export function redirect(url) {
  document.location = url
}

export function redirectToSuccess(history) {
  history.push("/")
}
