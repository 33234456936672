/* istanbul ignore file */

import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { ThemeProvider } from "@chakra-ui/core"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"

import { init, DatabaseListener } from "features/Database"

import store from "app/store"
import theme from "./theme"
import Header from "features/Header/container"
import Footer from "features/Footer/container"
import Routes from "routes/container"

import "features/basecss"

init()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <DatabaseListener />
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Header />
            <Switch>
              <Routes />
              <Route path="*">
                <div>404</div>
              </Route>
            </Switch>
            <Footer />
          </Router>
        </Suspense>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
