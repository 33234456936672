import { createSlice } from "@reduxjs/toolkit"
import { initialState } from "../../app/store"

export const slice = createSlice({
  name: "property_detail",
  initialState: initialState.property_detail
    ? initialState.property_detail
    : {
        image: "",
        amount: "",
        start_date: "",
        terms: "",
        percentage_completed: "",
        status: "",
        exists: false,
        properties: {},
      },
  reducers: {
    addProperty: (state, { payload }) => {
      if (!state.properties) {
        state.properties = {}
      }

      const { id } = payload

      state.properties[id] = payload
    },

    setProperties: (state, { payload }) => {
      state.properties = payload.properties
    },

    showProperty: (state, { payload: id }) => {
      const cachedData = state.properties[id]

      if (!cachedData) {
        state.exists = false
        return
      }

      const {
        image,
        amount,
        start_date,
        terms,
        percentage_completed,
        status,
      } = cachedData

      state.image = image
      state.amount = amount
      state.start_date = start_date
      state.terms = terms
      state.percentage_completed = percentage_completed
      state.status = status
      state.exists = true
    },
  },

  extraReducers: (builder) => {
    builder.addCase("reset", (state, action) => {
      state.image = ""
      state.amount = ""
      state.start_date = ""
      state.terms = ""
      state.percentage_completed = ""
      state.status = ""
      state.exists = false
      state.properties = {}
    })
  },
})

export const { addProperty, showProperty, setProperties } = slice.actions

export default slice.reducer
