import React from "react"
import PropTypes from "prop-types"
import { Layout } from "features/Layout"
import { Intro } from "features/Intro"
import * as styles from "./page.module.css"

export const PageScreen = ({ heading, leadText, body, isFullHeight }) => {
  return (
    <Layout fullHeight={isFullHeight}>
      <div className={styles.container}>
        <Intro heading={heading} text={leadText} />
        {body && <div dangerouslySetInnerHTML={{ __html: body }} />}
      </div>
    </Layout>
  )
}

PageScreen.propTypes = {
  heading: PropTypes.string,
  leadText: PropTypes.string,
  body: PropTypes.string,
  isFullHeight: PropTypes.bool,
}

PageScreen.defaultProps = {
  heading: "",
  leadText: "",
  body: "",
  isFullHeight: true,
}

export default PageScreen
